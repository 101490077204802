






























































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import AreaSelect from "@/components/area-select.vue";
import OrderLogistics from "@/components/order/order-logistics.vue";
import {
    apiOrderDetail,
    apiOrderChangeExpressPrice,
    apiOrderRemarks,
    apiOrderConfirm,
    apiOrderCancel,
    apiOrderChangeGoodsPrice,
    apiOrderAddressEdit,
} from "@/api/order/order";
import {apiSelffetchVerification} from "@/api/marketing/selffetch"
@Component({
    components: {
        LsDialog,
        AreaSelect,
        OrderLogistics,
    },
})
export default class OrderDetail extends Vue {
    // S Data

    // 订单详情ID
    id: any = 0;

    // 订单数据
    orderData: any = {
        admin_order_btn: {
            remark_btn: 1,
            cancel_btn: 0,
            confirm_btn: 0,
            logistics_btn: 0,
            refund_btn: 0,
            address_btn: 1,
            price_btn: 1,
        },
    };

    address: any = {
        province_id: "", //必填	int	所在地区:省id
        city_id: "", //必填	int	所在地区:市id
        district_id: "", //必填	int	所在地区:区id
        address: "", //必填	varchar	详细地址
    };

    // 商家备注
    remarks: string = "";

    // 运费更改
    express_price: string = "";

    // 商品价格
    goods_price: string = "";

    // E Data

    // S Methods
    // 获取订单详情
    getOrderDetail() {
        apiOrderDetail({ id: this.id }).then((res) => {
            this.orderData = res;
        });
    }

    // 取消订单
    orderCancel() {
        apiOrderCancel({ id: this.id }).then((res) => {
            this.getOrderDetail();
        });
    }

    // 提货核销
    selffetch() {
        apiSelffetchVerification({id: this.id}).then(() => {
            this.getOrderDetail();
        })
    }

    // 订单地址修改
    orderAddressSet() {
        apiOrderAddressEdit({
            id: this.id,
            ...this.address,
        }).then((res) => {
            this.getOrderDetail();
        });
    }

    // 确认收货
    orderConfirm() {
        apiOrderConfirm({ id: this.id }).then((res) => {
            this.getOrderDetail();
        });
    }

    // 商家备注
    postOrderRemarks() {
        apiOrderRemarks({
            id: [this.id],
            order_remarks: this.remarks,
        }).then((res) => {
            this.getOrderDetail();
        });
    }

    // 修改商品价格
    orderChangeGoodsPrice(id: Number) {
        if (this.goods_price == "") return this.$message.error("请输入价格");
        apiOrderChangeGoodsPrice({
            order_goods_id: id,
            change_price: this.goods_price,
        }).then((res) => {
            this.getOrderDetail();
            this.goods_price = "";
        });
    }

    // 修改运费
    orderChangeExpress() {
        if (this.express_price == "")
            return this.$message.error("请输入运费！");
        apiOrderChangeExpressPrice({
            id: this.id,
            express_price: this.express_price,
        }).then((res) => {
            this.getOrderDetail();
        });
    }
    // 商品信息的底部结算信息
    getSummaries(param: any) {
        const { columns, data } = param;
        const sums: any = [];
        columns.forEach((column: any, index: any) => {
            if (index === 0) {
                sums[0] = "总价";
                return;
            }
            const values = data.map((item: any) =>
                Number(item[column.property])
            );
            if (!values.every((value: any) => isNaN(value))) {
                if (index == 1) return;
                sums[index] = values.reduce((prev: any, curr: any) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                }, 0);
                if (index !== 2) {
                    sums[index] = "¥" + sums[index];
                }
                if(index == 6) {
                    sums[index] = this.orderData.total_goods_pay_price
                }
            }
        });

        return sums;
    }

    toUserDetail() {
        this.$router.push({
            path: "/user/user_details",
            query: {
                id: this.orderData.user_id,
            },
        });
    }

    // E Methods

    created() {
        this.id = this.$route.query.id;
        this.id && this.getOrderDetail();
    }
}
